import { Ref, computed, ref, toRef, watch } from 'vue'

export const usePaginatedArray = <T>({
  items,
  pageNumber = ref(1),
  pageSize: _pageSize,
}: {
  items: Ref<T[]>
  pageNumber?: Ref<number>
  pageSize: Ref<number> | number
}) => {
  const pageSize = toRef(_pageSize)
  const pageQty = computed(() => Math.ceil(items.value.length / pageSize.value))

  const pageIndex = computed({
    get: () => pageNumber.value - 1,
    set: (value) => {
      pageNumber.value = value + 1
    },
  })

  const pageOffset = computed(() => pageIndex.value * pageSize.value)
  const pageItems = computed(() => {
    const startIndex = pageOffset.value
    const endIndex = startIndex + pageSize.value

    return items.value.slice(startIndex, endIndex)
  })

  const totalItems = computed(() => items.value.length)

  watch(pageQty, () => {
    pageIndex.value = Math.min(pageIndex.value, Math.max(pageQty.value - 1, 0))
  }, { immediate: true })

  return {
    pageNumber,
    pageIndex,
    pageItems,
    pageOffset,
    pageQty,
    pageSize,
    totalItems,
  }
}
