import { Ref, computed } from 'vue'

import { Awaitable, toWritableRef } from '../../utils'
import { MaybeRef } from '../../types'

import { UseApiRequestMethod } from './types'
import { useApiOptions } from './internal/useApiOptions'
import {
  useApiAwaitable,
  useApiShared,
  useRefetchOnCacheClear, useRefetchOnRequestDataChange,
} from './internal/useApiShared'
import { UseApiOptions, UseApiSharedReturnPublic } from './internal/types'
import { omitPrivateKeys } from './internal/utils'

export type UseApiPaginatedOptions<Res, Req> =
  & Partial<UseApiOptions<Res, Req>>
  & {
    pageSize: MaybeRef<number>
    pageNumber?: MaybeRef<number>
    getTotalResults: (data: Res) => number
    getPageSize?: (data: Res) => number
  }

export type UseApiPaginatedReturn<Res, Req> =
  & UseApiSharedReturnPublic<Res, Req>
  & {
    pageNumber: Ref<number>
    pageSize: Ref<number>
    pagesTotal: Readonly<Ref<number>>
    total: Readonly<Ref<number>>
  }

export type UseApiPaginatedPage = {
  number: number
  index: number
  size: number
  skip: number
}

/**
 * Wrapper for {@link useApi} for using paginated APIs.
 *
 * @example
 * const { data, loading, error, pageNumber, pagesTotal, pageSize, total } = useApiPaginated(
 *   api.getSomethingPaginated,
 *   ({ number, skip, size }) => [{
 *     page: { number, size } // or { skip, size }
 *   }],
 *   {
 *     pageSize: 5,
 *     getTotalResults: (data) => data.totalResults,
 *   }
 * )
 *
 * // Changing page number or pageSize triggers a refetch
 * pageNumber.value = 2
 * pageSize.value = 10
 *
 * @example passing in existing refs, e.g. from useQuery
 * const pageSize = ref(5)
 * const pageNumber = ref(1)
 * const { data, loading, error, pagesTotal, total } = useApiPaginated(
 *   api.getSomethingPaginated,
 *   ({ number, skip, size }) => [{
 *     page: { number, size } // or { skip, size }
 *   }],
 *   {
 *     pageSize,
 *     pageNumber,
 *     getTotalResults: (data) => data.totalResults,
 *     refetch: true,
 *   }
 * )
 *
 * // Changing page number or pageSize triggers a refetch
 * pageNumber.value = 2
 * pageSize.value = 10
 */
export const useApiPaginated = <Res, const Req extends any[]>(
  requestMethod: UseApiRequestMethod<Res, Req>,
  requestData: (page: UseApiPaginatedPage) => NoInfer<Req> | null,
  optionsPartial: UseApiPaginatedOptions<Res, Req>,
): Awaitable<UseApiPaginatedReturn<Res, Req>> => {
  const { getTotalResults, getPageSize } = optionsPartial

  const pageSizeInternal = toWritableRef(optionsPartial, 'pageSize')
  const pageNumber = toWritableRef(optionsPartial, 'pageNumber', 1)

  const page = computed((): UseApiPaginatedPage => ({
    number: pageNumber.value,
    index: pageNumber.value - 1,
    size: pageSizeInternal.value,
    skip: (pageNumber.value - 1) * pageSizeInternal.value,
  }))
  const requestDataGetter = () => requestData(page.value)

  const options = useApiOptions(optionsPartial)
  const hookInternal = useApiShared(requestMethod, requestDataGetter, options)

  const pageSize = computed({
    get: () => (hookInternal.data.value && getPageSize)
      ? getPageSize(hookInternal.data.value)
      : pageSizeInternal.value,
    set: (value) => {
      pageSizeInternal.value = value
    },
  })

  const total = computed(() => {
    const data = hookInternal.data.value

    if (!data) {
      return 0
    }

    return getTotalResults(data)
  })

  const pagesTotal = computed(() => {
    const data = hookInternal.data.value

    if (!data) {
      return 0
    }

    return Math.ceil(getTotalResults(data) / pageSize.value)
  })

  useRefetchOnCacheClear(hookInternal, options)
  useRefetchOnRequestDataChange(requestDataGetter, hookInternal, options)

  const hook: UseApiPaginatedReturn<Res, Req> = omitPrivateKeys({
    ...hookInternal,
    pageNumber,
    pageSize,
    pagesTotal,
    total,
  })

  if (options.immediate.value) {
    void hook.request()
  }

  return useApiAwaitable(hook)
}
