import { MaybeRefOrGetter, Ref, computed, toValue } from 'vue'
import { useArrayEvery, useArraySome } from '@vueuse/core'

import { nonNullable } from '../../utils'

import { MaybeUnwrapRef } from '../../types'

import { UseApiSharedRequest, UseApiSharedState } from './internal/useApiShared'
import { UseApiSharedReturnPrivateKeys } from './internal/types'

export type ApisToCombine<Responses extends any[]> = MaybeRefOrGetter<{
  [K in keyof Responses]: Partial<MaybeUnwrapRef<ApiToCombine<Responses[K]>>>
}>

export type ApiToCombine<Res = any> =
  Omit<UseApiSharedState<Res, any>, UseApiSharedReturnPrivateKeys>
  & NoInfer<Omit<UseApiSharedRequest<Res>, 'request'>> & {
    request: (...args: any[]) => Promise<any>
  }

export type UseApiCombinedData<Responses extends any[]> = {
  [K in keyof Responses]: Responses[K] | null
}

export type UseApiCombinedReturn<Responses extends any[]> = Omit<
  ApiToCombine,
  | 'data'
  | 'lastRequestData'
  | 'dataTimestamp'
> & {
  request: () => Promise<void>
  data: Ref<UseApiCombinedData<Responses>>
  lastRequestData: Ref<UseApiCombinedData<Responses>>
}

/**
 * Combines multiple api instances into one,
 * allowing to use combined state and call actions on many apis at the same time.
 *
 * Useful when a component needs data from multiple different endpoints.
 *
 * @example
 * const apiOne = reactive(useApi(someFetcher))
 * const apiTwo = reactive(useApi(anotherFetcher))
 *
 * const {
 *   data,
 *   loading,
 *   error,
 *   retry,
 * } = useApiCombined([apiOne, apiTwo])
 *
 * @example
 * // you can pass apis without unwrapping or even pass partial api instances
 * const apiOne = useApi(someFetcher)
 * const { loading: loadingApiTwo, error: errorApiTwo } = reactive(useApi(anotherFetcher))
 *
 * // in this case only the first api will be retried since the retry methods wasn't passed in for the second api
 * const {
 *   data,
 *   loading,
 *   error,
 *   retry,
 * } = useApiCombined([apiOne, { loadingApiTwo, errorApiTwo }])
 *
 * @example can be used with specialised useApi composables as well, or even with other combined apis
 * const apiOne = reactive(useApi(someFetcher))
 * const apiTwo = reactive(useApi(anotherFetcher))
 * const apiCombined = useApiCombined([apiOne, apiTwo])
 * const apiPaginated = reactive(useApiPaginated(someFetcher, () => [], { ... }))
 * const apiInfinite = reactive(useApiInfinite(anotherFetcher, () => [], { ... }))
 *
 * const {
 *   data,
 *   loading,
 *   error,
 *   retry,
 * } = useApiCombined([apiCombined, apiPaginated, apiInfinite])
 */
export const useApiCombined = <Responses extends any[]>(
  apis: ApisToCombine<Responses>,
): NoInfer<UseApiCombinedReturn<Responses>> => {
  const createCombinedSome = (key: keyof ApiToCombine) => {
    return useArraySome(() => toValue(apis).map(api => api[key]), value => toValue(value))
  }

  const createCombinedAll = (key: keyof ApiToCombine) => {
    return useArrayEvery(() => toValue(apis).filter(api => key in api).map(api => api[key]), value => toValue(value))
  }

  const createCombinedFirst = (key: keyof ApiToCombine) => {
    return computed(() => toValue(apis).map(api => toValue(api[key])).find(nonNullable) ?? null)
  }

  const createForEach = (callback: (api: Partial<MaybeUnwrapRef<ApiToCombine>>) => void) => {
    return () => {
      toValue(apis).forEach((api) => {
        callback(api)
      })
    }
  }

  const createForEachAsync = (callback: (api: Partial<MaybeUnwrapRef<ApiToCombine>>) => Promise<void>) => {
    return async () => {
      await Promise.all(
        toValue(apis).map(callback),
      )
    }
  }

  const data = computed(() => {
    return toValue(apis).map(api => api.data ? toValue(api.data) : null) as UseApiCombinedData<Responses>
  })

  const lastRequestData = computed(() => {
    return toValue(apis).map(api => api.lastRequestData ? toValue(api.lastRequestData) : null) as UseApiCombinedData<Responses>
  })

  return {
    finished: createCombinedAll('finished'),
    loaded: createCombinedAll('loaded'),
    loading: createCombinedSome('loading'),
    fetching: createCombinedSome('fetching'),
    canceled: createCombinedSome('canceled'),
    error: createCombinedFirst('error'),
    clearData: createForEach(api => api.clearData?.()),
    cancel: createForEach(api => api.cancel?.()),
    retry: createForEachAsync(async api => api.retry?.()),
    request: createForEachAsync(async api => api.request?.()),
    data,
    lastRequestData,
  }
}
