import { omit } from 'lodash-es'

import { UseApiSharedReturnPrivate, UseApiSharedReturnPrivateKeys } from './types'
import { privateKeys } from './consts'

export const omitPrivateKeys = <T extends Partial<Pick<
  UseApiSharedReturnPrivate<any, any>,
  UseApiSharedReturnPrivateKeys
>>>(hook: T): Omit<T, UseApiSharedReturnPrivateKeys> => {
  return omit(hook, privateKeys)
}
