import { GtmPlugin, TrackEventOptions, useGtm } from '@gtm-support/vue-gtm'

import { useUser } from './useUser'

type GTMType = () => {
  addDataLayer: (data: Record<string, any>) => void
  triggerEvent: (options: TrackEventOptions, dataLayer?: any) => void
  isEnabled: () => boolean | undefined
  enable: () => void
  disable: () => void
  isDebugOn: () => boolean | undefined
  debugOn: () => void
  debugOff: () => void
}

const resetDataLayerHandler = function (this: any) {
  this.reset()
}

export const useGoogleTagManager: GTMType = () => {
  const gtm: GtmPlugin | undefined = useGtm()
  const { userInfo } = useUser()

  const resetDataLayer = (dataLayers: any) => {
    // https://developers.google.com/tag-platform/devguides/datalayer#reset
    dataLayers.push(resetDataLayerHandler)
  }

  const addDataLayer = (data: any) => {
    const dataLayers = gtm?.dataLayer()

    if (dataLayers && gtm?.enabled()) {
      resetDataLayer(dataLayers)
      dataLayers.push(data)
    }
  }

  const triggerEvent = (options: TrackEventOptions, dataLayer?: Record<string, any>) => {
    const user = userInfo.value
    if (gtm) {
      if (user) {
        addDataLayer({
          ...dataLayer,
          user: {
            email: user.email,
            roles: user.roles[0],
            firstName: user.firstName,
            lastName: user.lastName,
            environment: user.environment,
            id: user.id,
          },
        })
      }
      else {
        addDataLayer({
          ...dataLayer,
        })
      }
      gtm.trackEvent({
        ...options,
      })
    }
  }

  const isEnabled = () => gtm?.enabled()
  const enable = () => {
    gtm?.enable(true)
  }
  const disable = () => {
    gtm?.enable(false)
  }

  const isDebugOn = () => gtm?.debugEnabled()
  const debugOn = () => {
    gtm?.debug(true)
  }
  const debugOff = () => {
    gtm?.debug(false)
  }

  return {
    triggerEvent,
    addDataLayer,
    isEnabled,
    enable,
    disable,
    isDebugOn,
    debugOn,
    debugOff,
  }
}
