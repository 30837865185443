import { watchIgnorable } from '@vueuse/core'

import { Ref, isRef, nextTick, reactive, toRef, toValue } from 'vue'

import { v4 as uuidv4 } from 'uuid'

import { omit } from 'lodash-es'

import { arrayToMoved, truthy } from '../utils'

const idSymbol: unique symbol = Symbol('normalizedItemId')
export type DenormalizedTree<T> = { items?: T[] }
export type NormalizedTree<T> = {
  byId: NormalizedTreeById<T>
  parentIdById: Map<Id, Id | null>
  rootId: Id | string | null
}
export type NormalizedTreeById<T> = Map<Id, NormalizedItem<T>>
export type NormalizedItem<T> = { items: Id[]; [idSymbol]: string } & Omit<T, 'items'>
export type NormalizedItemAdd<T> = Omit<NormalizedItem<T>, typeof idSymbol>
export type NormalizedItemId = string
type Id = NormalizedItemId

// TODO: add tests
export const useNormalizedTreeHelpers = <T extends DenormalizedTree<T>>(normalizedTree: NormalizedTree<T>) => {
  const getItem = (id: Id) => normalizedTree.byId.get(id) ?? null

  const getItemId = (item: NormalizedItem<T>) => item[idSymbol]

  const getItemParentId = (id: Id) => {
    return normalizedTree.parentIdById.get(id) ?? null
  }

  const getItemParent = (id: Id): [Id | null, NormalizedItem<T> | null] => {
    const parentId = getItemParentId(id)

    return parentId ? [parentId, getItem(parentId)] : [null, null]
  }

  const setItem = (id: Id, item: NormalizedItem<T>) => {
    normalizedTree.byId.set(id, item)
  }

  const updateItem = (id: Id, data: Partial<Exclude<T, 'items'>>) => {
    const item = getItem(id)

    if (!item) {
      return
    }

    setItem(id, {
      ...item,
      ...data,
      items: item.items,
    })
  }

  const updateItemChildren = (id: Id, itemIds: Id[]) => {
    const item = getItem(id)

    if (!item) {
      return
    }

    setItem(id, {
      ...item,
      items: itemIds,
    })

    itemIds.forEach((itemId) => {
      if (normalizedTree.parentIdById.get(itemId) !== id) {
        normalizedTree.parentIdById.set(itemId, id)
      }
    })
  }

  const deleteItem = (id: Id) => {
    // deleting the root item would be bad
    if (id === normalizedTree.rootId) {
      return
    }

    const item = getItem(id)

    if (!item) {
      return
    }

    const [parentId, parentItem] = getItemParent(id)

    if (parentId && parentItem) {
      updateItemChildren(parentId, parentItem.items.filter(otherId => otherId !== id))
    }

    normalizedTree.byId.delete(id)
    normalizedTree.parentIdById.delete(id)
  }

  const toInsertedAt = (ids: Id[], id: Id, index?: number) => {
    const newIds = ids.slice()

    newIds.splice(index ?? ids.length - 1, 0, id)

    return newIds
  }

  const addItem = (item: NormalizedItemAdd<T>, parentId?: Id, parentIndex?: number) => {
    const id = uuidv4()

    setItem(id, { ...item, [idSymbol]: id } as NormalizedItem<T>)

    if (parentId === undefined) {
      return id
    }

    const parentItem = getItem(parentId)

    if (parentItem) {
      updateItemChildren(parentId, toInsertedAt(parentItem.items, id, parentIndex))
    }

    return id
  }

  const moveItem = (id: Id, newParentId: Id, index?: number) => {
    const item = getItem(id)
    const currentParentId = getItemParentId(id)
    const currentParent = currentParentId && getItem(currentParentId)
    const newParent = getItem(newParentId)

    if (!item || !newParent) {
      return
    }

    if (currentParent) {
      if (currentParentId === newParentId) {
        const items = currentParent.items

        updateItemChildren(currentParentId, arrayToMoved(items, items.indexOf(id), index ?? items.length))
        return
      }

      updateItemChildren(currentParentId, currentParent.items.filter(otherId => otherId !== id))
    }

    updateItemChildren(newParentId, toInsertedAt(newParent.items, id, index))
  }

  const getMaxDepth = (id: Id, curDepth = 0): number => {
    const childIds = getItem(id)?.items

    if (!childIds) {
      return curDepth
    }

    return childIds.reduce((maxDepth: number, childId) => {
      const childDepth = getMaxDepth(childId, curDepth + 1)

      return Math.max(maxDepth, childDepth)
    }, curDepth)
  }

  return {
    rootId: toRef(normalizedTree, 'rootId'),

    getItem,
    getItemParentId,
    setItem,
    updateItem,
    updateItemChildren,
    deleteItem,
    addItem,
    moveItem,
    getMaxDepth,
    getItemId,
  }
}

export const useNormalizedTree = <T extends DenormalizedTree<T>>(
  root: Ref<T | null | undefined> | T,
  { watch: shouldWatch = 'both' }: { watch?: 'both' | 'normalized' | 'denormalized' | false } = {},
) => {
  const normalizeTreeInner = (
    item: T,
    itemById: Map<Id, NormalizedItem<T>>,
    parentIdById: Map<Id, Id | null>,
    generatedIdFor: Map<Id, boolean>,
    parentId?: Id,
  ) => {
    const id = uuidv4()
    const normalizedItem: NormalizedItem<T> = {
      [idSymbol]: id,
      ...item,
      items: [],
    }

    itemById.set(id, normalizedItem)
    parentIdById.set(id, parentId ?? null)

    normalizedItem.items = (item.items ?? []).map((item) => {
      return normalizeTreeInner(item, itemById, parentIdById, generatedIdFor, id)
    })

    return id
  }

  const normalizeTreePure = (root: T | null | undefined): NormalizedTree<T> => {
    if (!root) {
      return { byId: new Map(), parentIdById: new Map(), rootId: null }
    }

    const byId = new Map<Id, NormalizedItem<T>>()
    const parentIdById = new Map<Id, Id | null>()
    const generatedIdFor = new Map<Id, boolean>()
    const rootId = normalizeTreeInner(root, byId, parentIdById, generatedIdFor)

    return { byId, parentIdById, rootId }
  }

  const denormalizeTreeInner = (tree: NormalizedTree<T>, id: Id): T | null => {
    const item = tree.byId.get(id)

    if (!item) {
      return null
    }

    const denormalizedItem = {
      ...item,
      items: item.items.map(childId => denormalizeTreeInner(tree, childId)).filter(truthy),
    }
    const keysToOmit = [
      idSymbol,
      item.items.length === 0 && 'items',
    ].filter(truthy)

    return omit(denormalizedItem, ...keysToOmit) as unknown as T
  }

  const denormalizeTreePure = (tree: NormalizedTree<T>): T | null => {
    return tree.rootId === null ? null : denormalizeTreeInner(tree, tree.rootId)
  }

  const initialTree: NormalizedTree<T> = normalizeTreePure(toValue(root))
  const normalizedTree = reactive(initialTree) as NormalizedTree<T>

  const normalizeTree = (value: T) => {
    Object.assign(normalizedTree, normalizeTreePure(value))
  }

  const denormalizeTree = () => {
    return denormalizeTreePure(normalizedTree)
  }

  let ignoreExternalUpdates = (cb: () => void) => cb()
  let ignoreInternalUpdates = (cb: () => void) => cb()
  let ignoreAsyncExternalUpdate: boolean

  if (isRef(root) && (shouldWatch === 'both' || shouldWatch === 'denormalized')) {
    ignoreExternalUpdates = watchIgnorable(root, (value) => {
      if (!ignoreAsyncExternalUpdate) {
        ignoreInternalUpdates(() => {
          Object.assign(normalizedTree, normalizeTreePure(value))
        })
      }

      ignoreAsyncExternalUpdate = false
    }).ignoreUpdates
  }

  if (isRef(root) && (shouldWatch === 'both' || shouldWatch === 'normalized')) {
    ignoreInternalUpdates = watchIgnorable(normalizedTree, (normalizedTree) => {
      ignoreExternalUpdates(() => {
        root.value = denormalizeTreePure(normalizedTree)
      })

      // If the update is scheduled for the next tick we want to ignore it as well
      ignoreAsyncExternalUpdate = true
      void nextTick(() => {
        ignoreAsyncExternalUpdate = false
      })
    }, { deep: true }).ignoreUpdates
  }

  const actions = useNormalizedTreeHelpers(normalizedTree)

  return {
    normalizedTree,

    normalizeTree,
    denormalizeTree,

    ...actions,
  }
}
