import { usePropagatingEvent } from '../usePropagatingEvent'

export const useFormEvents = () => {
  // Goes from form to fields
  const validationError = usePropagatingEvent('formValidationError', 'down')

  // Goes from fields to form
  const fieldError = usePropagatingEvent('formError', 'up')

  return { validationError, fieldError }
}
