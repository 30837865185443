import { EventHook, EventHookTrigger, IsAny, createEventHook } from '@vueuse/core'
import { InjectionKey, inject, provide } from 'vue'

import { injectWithSelf } from '../utils/injectWithSelf'

const eventInjectionKeys = new Map<string, InjectionKey<any>>()

/**
 * Allows listening to an event that is triggered only by child or only by parent components
 * on arbitrary levels of hierarchy.
 */
export const usePropagatingEvent = <T = void>(name: string, direction: 'down' | 'up' | 'both') => {
  const key = eventInjectionKeys.get(name) ?? Symbol(name)

  eventInjectionKeys.set(name, key)

  const createHook = (): EventHook<T> => {
    const hook = injectWithSelf<EventHook<T>>(key)

    // Don't modify the hook if it was provided within the same instance
    if (hook && hook !== inject(key)) {
      return hook
    }

    if (!hook) {
      return createEventHook<T>()
    }

    switch (direction) {
      case 'down': {
        const oldHook = hook
        const newHook = createEventHook<T>()

        // Create a new hook that will trigger the hook of the child components but won't be triggered by them
        // This way propagation will only work from parent to children
        return {
          ...newHook,
          on: (cb) => {
            const offs = [oldHook.on(cb), newHook.on(cb)]

            return {
              off: () => {
                offs.forEach(({ off }) => {
                  off()
                })
              },
            }
          },
          off: (cb) => {
            oldHook.off(cb)
            newHook.off(cb)
          },
        }
      }

      case 'up': {
        const oldHook = hook
        const newHook = createEventHook<T>()
        const trigger: EventHookTrigger<T> = async (...param: IsAny<T> extends true ? unknown[] : [T, ...unknown[]]) => {
          await Promise.all([oldHook.trigger(...param), newHook.trigger(...param)])

          return []
        }

        // Create a new hook that will trigger the hook of the parent component but won't be triggered by it
        // This way propagation will only work from child to parent
        return {
          ...newHook,
          trigger,
        }
      }

      case 'both':
        // Allow propagating both ways
        return hook
    }
  }

  const hook = createHook()

  provide(key, hook)

  return hook
}
