import { watch } from 'vue'
import { tryOnUnmounted } from '@vueuse/core'

import { RefOrGetter } from '../types'

let lockCount = 0
let lockRaf = -1

const lock = () => {
  lockCount += 1
  const scrollingElement = document.documentElement
  cancelAnimationFrame(lockRaf)

  if (scrollingElement.style.overflow === 'hidden') {
    return
  }

  const widthWithScrollbar = scrollingElement.offsetWidth

  scrollingElement.style.overflow = 'hidden'

  const widthNoScrollbar = scrollingElement.offsetWidth

  scrollingElement.style.paddingRight = `${widthNoScrollbar - widthWithScrollbar}px`
}

const unlock = () => {
  lockCount = Math.max(lockCount - 1, 0)

  if (lockCount > 0) {
    return
  }

  // Sync unlock with vue transitions
  cancelAnimationFrame(lockRaf)

  lockRaf = requestAnimationFrame(() => {
    cancelAnimationFrame(lockRaf)

    lockRaf = requestAnimationFrame(() => {
      const scrollingElement = document.documentElement
      scrollingElement.style.overflow = ''
      scrollingElement.style.paddingRight = ''
    })
  })
}

export const useScrollLock = (locked: RefOrGetter<boolean>) => {
  let _locked = false

  watch(locked, (value) => {
    if (value === _locked) {
      return
    }

    if (value) {
      lock()
    }
    else {
      unlock()
    }

    _locked = value
  }, { immediate: true })

  tryOnUnmounted(() => {
    if (_locked) {
      unlock()
    }
  })
}
