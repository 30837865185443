import { onBeforeUpdate, reactive, shallowReadonly } from 'vue'

/**
 * Composable that creates a reliable way to an array to elements inside of v-for.
 *
 * @example
 * <template>
 *   <div v-for="(item, index) in items" :ref="setRefByIndex(index)"></div>
 * </template>
 *
 * <script lang="ts" setup>
 *   defineProps<{ items: Array<unknown> }>
 *
 *   const { refs, setRefByIndex } = useTemplateRefsArray<HTMLElement>()
 *
 *   onMounted(() => {
 *     refs.forEach((el) => {
 *        console.log(el)
 *     }
 *   })
 * </script>
 */
export const useTemplateRefsArray = <T>() => {
  const refs: T[] = reactive([])

  const setRefByIndex = (index: number): (el?: unknown) => void => {
    return (el?: unknown): void => {
      if (el) {
        refs[index] = el as T
      }
    }
  }

  onBeforeUpdate(() => {
    refs.length = 0
  })

  return {
    refs: shallowReadonly(refs),
    setRefByIndex,
  }
}
