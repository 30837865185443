import { FormContext } from 'vee-validate'

export const formProps = [
  'values',
  'errors',
  'setErrors',
  'setFieldError',
  'setValues',
  'validate',
  'useFieldModel',
] as const satisfies Array<keyof FormContext>
