import { onBeforeUnmount, onMounted, toValue } from 'vue'
import { RouteLocationNormalized, onBeforeRouteLeave } from 'vue-router'

import { useEventListener } from '@vueuse/core'

import { SingleSpaCustomEventDetail } from 'single-spa'

import { RefOrGetter } from '../types'

import { useUser } from './useUser'

let guardCounter = 0
let guardChecks: Array<RefOrGetter<boolean>> = []

const guardExternal = (event: Event): void => {
  if (process.env.COMMAND === 'serve') {
    return
  }

  if (guardChecks.some(check => toValue(check))) {
    event.preventDefault()
    event.returnValue = true
  }
}

export const useExternalNavigationGuard = (shouldGuard: RefOrGetter<boolean>) => {
  const { isLoggedIn } = useUser()

  onMounted(() => {
    guardChecks.push(() => toValue(shouldGuard) && isLoggedIn.value)

    if (guardCounter === 0) {
      window.addEventListener('beforeunload', guardExternal)
    }

    guardCounter += 1
  })

  onBeforeUnmount(() => {
    guardChecks = guardChecks.filter(check => check !== shouldGuard)
    guardCounter = Math.max(guardCounter - 1, 0)

    if (guardCounter === 0) {
      window.removeEventListener('beforeunload', guardExternal)
    }
  })
}

export const useInternalNavigationGuard = (
  shouldGuard:
  | RefOrGetter<boolean>
  | ((to: RouteLocationNormalized, from: RouteLocationNormalized) => boolean),
  // eslint-disable-next-line no-alert
  onLeave: () => boolean = () => window.confirm('Are you sure? All unsaved changes will be lost.'),
) => {
  const { isLoggedIn } = useUser()

  let leavingViaSingleSpa = false

  useEventListener(window, 'single-spa:before-routing-event', () => {
    leavingViaSingleSpa = true
  })

  onBeforeRouteLeave((to, from) => {
    if (process.env.COMMAND === 'serve') {
      return true
    }

    const shouldGuardResult = !leavingViaSingleSpa
      && (typeof shouldGuard === 'function' ? shouldGuard(to, from) : shouldGuard.value)
      && isLoggedIn.value

    leavingViaSingleSpa = false

    return shouldGuardResult ? onLeave() : true
  })
}

export const useSingleSpaNavigationGuard = (
  shouldGuard: RefOrGetter<boolean>,
  // eslint-disable-next-line no-alert
  onLeave: () => boolean = () => window.confirm('Are you sure? All unsaved changes will be lost.'),
) => {
  const { isLoggedIn } = useUser()

  let leavingViaVueRouter = false

  onBeforeRouteLeave(() => {
    leavingViaVueRouter = true
  })

  useEventListener(
    window,
    'single-spa:before-routing-event',
    ({ detail: { cancelNavigation } }: CustomEvent<SingleSpaCustomEventDetail>) => {
      if (process.env.COMMAND === 'serve') {
        return
      }

      if (!leavingViaVueRouter && toValue(shouldGuard) && isLoggedIn.value && !onLeave()) {
        cancelNavigation?.()
      }

      leavingViaVueRouter = false
    },
  )
}

export const useNavigationGuard = (shouldGuard: RefOrGetter<boolean>) => {
  useExternalNavigationGuard(shouldGuard)
  useInternalNavigationGuard(shouldGuard)
  useSingleSpaNavigationGuard(shouldGuard)
}
