import { Ref, computed, toValue } from 'vue'

import { AccountInfoOption, AccountInfoOptionId, useUser } from '../useUser'

import { AccessAction, AccessFeature, AccessResource } from './types'

export const useAccessControlBase = () => {
  const { userInfo, accountInfo, isSuperAdmin } = useUser()

  const access = computed(() => userInfo.value?.access)
  const features = computed(() => userInfo.value?.features)
  const accountOptions = computed(() => accountInfo.value?.options)

  const hasPermission = (resource: AccessResource, action?: AccessAction): boolean => {
    if (toValue(isSuperAdmin) || toValue(access)?.accessAll) {
      return true
    }

    const actions = toValue(access)?.resources[resource] ?? []

    return action ? actions.includes(action) : actions.length > 0
  }

  const permission = (resource: AccessResource, action?: AccessAction): Readonly<Ref<boolean>> => {
    return computed(() => hasPermission(resource, action))
  }

  const hasFeature = (feature: AccessFeature): boolean => {
    return Boolean(toValue(features)?.includes(feature))
  }

  const feature = (feature: AccessFeature): Readonly<Ref<boolean>> => {
    return computed(() => hasFeature(feature))
  }
  const getAccountOption = (id: AccountInfoOptionId): AccountInfoOption | null => {
    return toValue(accountOptions)?.find(({ optionId }) => optionId === id) ?? null
  }

  const accountOption = (id: AccountInfoOptionId): Readonly<Ref<AccountInfoOption | null>> => {
    return computed(() => getAccountOption(id))
  }

  /**
   * Checks if the account option with the given ID is enabled:
   * - returns `false` if the option is missing or not enabled
   * - returns `true` for roles without an account object (Super Admin, Observer)
   */
  const hasEnabledAccountOption = (id: AccountInfoOptionId): boolean => {
    return toValue(accountOptions)
      ? accountOption(id).value?.enabled ?? false
      : true // default to true for roles without account options
  }

  const enabledAccountOption = (id: AccountInfoOptionId): Readonly<Ref<boolean>> => {
    return computed(() => hasEnabledAccountOption(id))
  }

  return {
    hasPermission,
    permission,
    hasFeature,
    feature,
    getAccountOption,
    accountOption,
    hasEnabledAccountOption,
    enabledAccountOption,
  }
}
