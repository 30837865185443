import { isRef } from 'vue'

import { Awaitable } from '../../utils'

import { UseApiPromise, UseApiRequestArg, UseApiRequestMethod } from './types'
import { useApiArgs, useApiOptions } from './internal/useApiOptions'
import {
  useApiAwaitable,
  useApiShared,
  useRefetchOnCacheClear,
  useRefetchOnRequestDataChange,
} from './internal/useApiShared'
import { UseApiOptions, UseApiSharedReturnPublic } from './internal/types'
import { omitPrivateKeys } from './internal/utils'

export type UseApiReturn<Res, Req> = UseApiSharedReturnPublic<Res, Req>

export type UseApi = {
  // Request with non-optional parameters
  <Res, const Req extends any[]>(
    requestMethod: UseApiRequestMethod<Res, Req>,
    requestData: UseApiRequestArg<NoInfer<Req>>,
    options?: Partial<UseApiOptions<Res, Req>>,
  ): Awaitable<UseApiReturn<Res, Req>>

  // Request with no parameters
  <Res>(
    requestMethod: () => UseApiPromise<Res>,
    options?: Partial<UseApiOptions<Res, unknown>>,
  ): Awaitable<UseApiReturn<Res, unknown>>
}

/**
 * Hook for working with any REST APIs (or really any promise-returning functions).
 * Can be used both for the generated API-calling methods, as well as the legacy manually written API-calling methods.
 * Note: You should call this in the body of a component or another hook, not in functions!
 *
 * This hook is based on {@link import('@vueuse/core').useFetch} or its equivalent from Nuxt.
 *
 * @example load some data in the body of a component once
 * // Pass the api method and the array of arguments that should be passed to it
 * // Note that the request is executed immediately!
 * // Component must handle loading and error states
 * const { data, error, loading } = useApi(api.getSomething, [props.id])
 *
 * // if the api method doesn't take any arguments, the second argument can be omitted
 * const { data, error, loading } = useApi(api.getSomethingElse)
 *
 * @example load some data in the body of a component once - using await and Suspense
 * const { data, error } = await useApi(api.getSomething, [props.id])
 *
 * // Component must handle only the error state, loading state is handled by the wrapping Suspense component
 *
 * @example load some data on demand
 * // Note that the request data is still passed into the hook!
 * // You can use {@link useApiManual} if you want to pass the data into the request function
 * // To ensure that the up-to-date request data is sent, pass it as a getter
 * const { data, error, request } = await useApi(
 *   api.getSomething,
 *   () => [props.id],
 *   { immediate: false }
 * )
 *
 * const handleClick = async () => {
 *   const newData = await request() // data is returned from request, data and error are set to the refs
 *
 *   try {
 *     await request(true)
 *   } catch (error) {
 *     // error can be thrown by passing true to request
 *   }
 * }
 *
 * @example load some data whenever the request data changes
 * const params = ref({})
 * // Using a getter as request data
 * useApi(
 *   api.getSomething,
 *   () => [props.id, params.value],
 *   { refetch: true }
 * )
 *
 * // Using an array of refs and getters as request data
 * useApi(
 *   api.getSomething,
 *   [() => props.id, params],
 *   { refetch: true }
 * )
 *
 * // Using a computed returning an array as request data
 * const requestData = computed(() => [props.id, params.value])
 * useApi(
 *   api.getSomething,
 *   requestData,
 *   { refetch: true }
 * )
 *
 * @example disabling api conditionally
 * // Using the `enabled` option
 * const enabled = ref(true)
 * const requestData = ref('one')
 * useApi(
 *   api.query2,
 *   [requestData],
 *   { refetch: true, enabled }
 * )
 *
 * enabled.value = false
 * // Won't trigger a refetch
 * requestData.value = 'two'
 *
 * // Returning null as request data
 * // Using the `enabled` option
 * const requestData = ref('one')
 * useApi(
 *   api.query2,
 *   () => requestData.value ? [requestData] : null,
 *   { refetch: true }
 * )
 *
 * // Won't trigger a refetch
 * requestData.value = null
 *
 * @example using request event hooks
 * const { onData, onError } = useApi(api.getSomething)
 *
 * onData(console.log)
 * onError(console.error)
 *
 * @example new requests initiated by the same hook overwrite any in-progress requests, ensuring that only the latest request's results are applied
 * const { data, request, cancel } = useApi(api.getSomething)
 *
 * // data will only be updated once
 * // when used with OpenAPI-generated api methods, the request will be properly canceled as well
 * request()
 * request()
 *
 * // request can be canceled manually
 * cancel()
 *
 * @example parallel queries
 * const query1 = useApi(api.query1)
 * const query2 = useApi(api.query2)
 *
 * const { loading } = useApiCombined([query1, query2])
 *
 * @example sequential queries, see {@link useApiCombined}
 * const query1 = useApi(api.query1)
 * const query2 = useApi(
 *   api.query2,
 *   () => query1.data.value ? [query1.data.value.id] : null,
 *   { refetch: true }
 * )
 *
 * @example caching
 * // Cache using the request method as the cache key
 * const { data, request } = useApi(api.getSomething, { cache: true })
 *
 * // Cache using a string key and function arguments as the cache key
 * const { data, request } = useApi(
 *   (...args) => getSomething(...args),
 *   [...args],
 *   { cache: 'cache-key' }
 * )
 *
 * // Clear cache
 * const { clearCache } = useApi(api.getSomething)
 * // or
 * const { clearCacheFor } = useApiCache()
 * clearCacheFor(api.getSomething)
 *
 * @example clearCacheFor option
 * // Let's say you have some general data that is fetched in App.vue, cached and put in a store
 * // Note that fetching is different from loading in that it is only true when data is loading AND empty
 * const { data, fetching } = useApi(api.getMonetizationGeneralData, {
 *  immediate: false,
 *  cache: true,
 *  cacheLifetime: 1000 * 60 * 60,
 *  refetchOnCacheClear: true
 * })
 *
 * // Then in some other place of the app you call an api that invalidates that cache
 * // In my case, the general data has a counter of how many placements each publisher has,
 * // and I want to update that when a placement is deleted
 * const { request: deletePlacementInternal } = useApiManual(
 *  api.deletePlacement,
 *  { clearCacheFor: [api.getMonetizationGeneralData] }
 * )
 *
 * // The general data will automatically be reloaded when the api call finishes
 * deletePlacementInternal()
 *
 * // Note that clearCacheFor is only triggered on successful requests
 * // You need to manually trigger cache clearing on error if it is required
 * const { clearCacheFor } = useApiCache()
 * const { onError } = useApiManual(
 *   api.deletePlacement,
 *   { clearCacheFor: [api.getMonetizationGeneralData] }
 * )
 *
 * onError(() => {
 *   clearCacheFor(api.getMonetizationGeneralData)
 * })
 */
export const useApi: UseApi = <Res, Req extends any[]>(
  requestMethod: (...args: Req) => UseApiPromise<Res>,
  ...args: unknown[]
): Awaitable<UseApiReturn<Res, Req>> => {
  const { requestData, optionsPartial } = useApiArgs<Req, UseApiOptions<Res, Req>>(args)
  const options = useApiOptions(optionsPartial)
  const hook = useApiShared(requestMethod, requestData, options)

  useRefetchOnCacheClear(hook, options)

  if (isRef(requestData) || typeof requestData === 'function') {
    useRefetchOnRequestDataChange(requestData, hook, options)
  }

  if (options.immediate.value) {
    void hook.request()
  }

  return useApiAwaitable(omitPrivateKeys(hook))
}
