import { Ref, computed } from 'vue'

import { useApiCache } from './useApiCache'

export const useApiCacheFor = <T extends PromiseLike<unknown> = PromiseLike<unknown>>(
  key: Ref<Function | string | null>,
) => {
  const { getCacheFor } = useApiCache()

  return computed(() => {
    if (!key.value) {
      return null
    }

    return getCacheFor<T>(key.value) ?? null
  })
}
