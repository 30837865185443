import { objectHash, sha256 } from 'ohash'
import { createEventHook } from '@vueuse/core'

import { arrayify } from '../../utils'
import { useGlobalState } from '../useGlobalState'
import { useMutableWatchers } from '../useMutableWatchers'

type CacheHashedKey = string

type CacheMap = Map<CacheHashedKey, CacheItem>

type CacheItem = {
  promise: PromiseLike<unknown>
  // for debugging
  resolvedValue?: unknown
  timeoutId: number
}

export type ApiCacheRecord<T extends PromiseLike<unknown>> = {
  get: (key: CacheKey) => T | undefined
  set: (key: CacheKey, promise: T, lifetime: number) => void
  remove: (key: CacheKey, triggerHook?: boolean) => void
  clear: () => void
  onClear: (fn: () => void) => { off: () => void }
  offClear: (fn: () => void) => void
}

export type CacheKey = Record<string, unknown> | unknown[]

/**
 * Provides globally shared cache for {@link useApi} composables.
 *
 * The cache is available via `window.__globalState.apiCache.value.cacheMaps` for debugging purposes.
 */
export const useApiCache = () => {
  const caches = useGlobalState('apiCache', () => ({
    strCache: new Map<string, CacheMap>(),
    funcCache: new WeakMap<Function, CacheMap>(),
    funcMap: new WeakMap<Function, Function>(),
    cacheMaps: new Set<CacheMap>(),
    clearCacheHook: createEventHook<Function | string>(),
    clearCacheHookListeners: new Set<() => void>(),
  }), true)

  const getAliasedCacheKey = (cacheKey: Function | string): Function | string => {
    return typeof cacheKey === 'function' ? caches.value.funcMap.get(cacheKey) ?? cacheKey : cacheKey
  }

  const getCacheMapFor = (cacheKey: Function | string): CacheMap => {
    const aliasedCacheKey = getAliasedCacheKey(cacheKey)
    const isFunc = typeof aliasedCacheKey === 'function'
    let cache = isFunc ? caches.value.funcCache.get(aliasedCacheKey) : caches.value.strCache.get(aliasedCacheKey)

    if (!cache) {
      cache = new Map<CacheHashedKey, CacheItem>()
      caches.value.cacheMaps.add(cache)

      if (isFunc) {
        caches.value.funcCache.set(aliasedCacheKey, cache)
      }
      else {
        caches.value.strCache.set(aliasedCacheKey, cache)
      }
    }

    return cache
  }

  const hashKey = (key: CacheKey): CacheHashedKey => {
    return sha256(objectHash(key))
  }

  const { addWatchers } = useMutableWatchers()

  const addClearCacheListener = (listener: (key: Function | string) => void) => {
    const { off } = caches.value.clearCacheHook.on(listener)

    const clearListener = () => {
      caches.value.clearCacheHookListeners.delete(clearListener)
      off()
    }

    caches.value.clearCacheHookListeners.add(clearListener)

    addWatchers([clearListener])
  }

  const getCacheFor = <T extends PromiseLike<unknown> = PromiseLike<unknown>>(
    cacheKey: Function | string,
  ): ApiCacheRecord<T> => {
    const aliasedCacheKey = getAliasedCacheKey(cacheKey)
    const cache = getCacheMapFor(aliasedCacheKey)
    const localClearCacheHook = createEventHook<void>()

    const onCacheClear = (clearedKey: Function | string) => {
      if (clearedKey === aliasedCacheKey) {
        localClearCacheHook.trigger()
      }
    }

    addClearCacheListener(onCacheClear)

    const clearLifetimeTimeout = (hashedKey: CacheHashedKey): void => {
      const record = cache.get(hashedKey)

      if (record) {
        window.clearTimeout(record.timeoutId)
      }
    }

    const get = (key: CacheKey): T | undefined => {
      return cache.get(hashKey(key))?.promise as T | undefined
    }

    const remove = (key: CacheKey, triggerHook = true): void => {
      const hashedKey = hashKey(key)

      clearLifetimeTimeout(hashedKey)
      cache.delete(hashedKey)

      if (triggerHook) {
        caches.value.clearCacheHook.trigger(aliasedCacheKey)
      }
    }

    const set = (key: CacheKey, promise: T, lifetime: number): void => {
      const hashedKey = hashKey(key)
      clearLifetimeTimeout(hashedKey)

      const timeoutId = window.setTimeout(() => {
        cache.delete(hashedKey)
        caches.value.clearCacheHook.trigger(aliasedCacheKey)
      }, lifetime)

      cache.set(hashedKey, { promise, timeoutId })

      Promise.resolve(promise).then(
        (value) => {
          if (cache.get(hashedKey)?.promise === promise) {
            cache.set(hashedKey, {
              promise: Promise.resolve(value),
              resolvedValue: value,
              timeoutId,
            })
          }
        },
        () => {
          if (cache.get(hashedKey)?.promise === promise) {
            remove(key, false)
          }
        },
      )
    }

    const clear = (): void => {
      Array.from(cache.keys()).forEach((key) => {
        clearLifetimeTimeout(key)
      })

      cache.clear()
      caches.value.clearCacheHook.trigger(aliasedCacheKey)
    }

    return { get, set, remove, clear, onClear: localClearCacheHook.on, offClear: localClearCacheHook.off }
  }

  const clearCacheFor = (key: (Function | string) | Array<Function | string>): void => {
    arrayify(key).forEach((key) => {
      getCacheFor(key)?.clear()
    })
  }

  const clearAllCache = () => {
    // We clear the listeners first since we don't want the refetchOnCacheClear to trigger
    // That would be bad in case of auth data changing
    caches.value.clearCacheHookListeners.forEach((clearListener) => {
      clearListener()
    })
    caches.value.cacheMaps.forEach((cache) => {
      cache.clear()
    })
  }

  // Allows aliasing function cache keys as other function cache keys
  const aliasCacheKeys = (entries: Array<[cacheKey: Function, aliasedCacheKey: Function]>): void => {
    entries.forEach(([cacheKey, aliasedCacheKey]) => {
      caches.value.funcMap.set(cacheKey, aliasedCacheKey)
    })

    addWatchers(
      entries.map(([cacheKey]) =>
        () => caches.value.funcMap.delete(cacheKey),
      ),
    )
  }

  return { getCacheFor, clearCacheFor, clearAllCache, aliasCacheKeys }
}
