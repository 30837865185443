import { ShallowRef, computed, isRef, shallowRef } from 'vue'

const globalStateKey = '__globalState'

type GlobalState = Record<string, ShallowRef>

type WindowWithGlobalState = typeof window & {
  [K in typeof globalStateKey]?: GlobalState
}

/**
 * Allows sharing state between microfrontends by storing it globally.
 * The returned value is a shallowRef that works across microfrontends.
 * The default value can be a factory function, it will only be called when accessing the state when it's empty.
 */
export function useGlobalState<T>(key: string): ShallowRef<T | undefined>
export function useGlobalState<T>(key: string, defaultValue: T, treatDefaultAsFactory?: false | undefined): ShallowRef<T>
export function useGlobalState<T>(key: string, defaultValue: (() => T), treatDefaultAsFactory: true): ShallowRef<T>
export function useGlobalState<T>(key: string, defaultValue?: T | (() => T), treatDefaultAsFactory?: boolean): ShallowRef<T | undefined> {
  const getGlobalState = (): GlobalState => {
    const windowWithGlobalState = window as WindowWithGlobalState

    if (!windowWithGlobalState[globalStateKey]) {
      windowWithGlobalState[globalStateKey] = {}
    }

    return windowWithGlobalState[globalStateKey]
  }

  const getGlobalStateKey = (key: string): ShallowRef => {
    const globalState = getGlobalState()

    if (!(key in globalState)) {
      globalState[key] = shallowRef()
    }

    if (!isRef(globalState[key])) {
      globalState[key] = shallowRef(globalState[key]!.value)
    }

    return globalState[key]!
  }

  let defaultFactoryResult: T | undefined

  const getDefaultValue = (): T | undefined => {
    if (defaultValue === undefined) {
      return undefined
    }

    if (treatDefaultAsFactory) {
      if (defaultFactoryResult === undefined) {
        defaultFactoryResult = (defaultValue as () => T)()
      }

      return defaultFactoryResult
    }
    else {
      return defaultValue as T
    }
  }

  const setValue = (value: T) => {
    const valueRef = getGlobalStateKey(key)
    valueRef.value = value

    return valueRef.value
  }

  const getValue = () => {
    let value = getGlobalStateKey(key).value

    if (value === undefined) {
      value = getDefaultValue()

      if (value !== undefined) {
        value = setValue(value)
      }
    }

    return value
  }

  // Ensure initial value is set
  getValue()

  return computed({
    get: getValue,
    set: setValue,
  })
}
