import { ComponentInternalInstance, nextTick, onUpdated } from 'vue'

import { nextFrame } from '../utils'

/**
 * A version of {@link import('vue').onUpdated} lifecycle hook in which it's more or less safe to update the state
 * of the component without causing an infinite loop. Useful when needing to update the state of the component
 * in response to rendering caused by slot changes.
 */
export const onUpdatedSafe = (hook: () => void, target?: ComponentInternalInstance | null) => {
  let infiniteLoopGuard = false

  return onUpdated(async () => {
    if (infiniteLoopGuard) {
      infiniteLoopGuard = false
      return
    }

    infiniteLoopGuard = true
    hook()
    await nextTick()
    await nextFrame()
    infiniteLoopGuard = false
  }, target)
}
