import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr'
import { Ref, computed, readonly, ref } from 'vue'

import { useGlobalState } from '../useGlobalState'
import { AuthInfo } from '../useUser'

import { RpcConnection, RpcConnectionState } from './types'

type HubConnectionInternal = HubConnection & {
  reactiveStateSetters?: Array<(value: RpcConnectionState) => void>
  connectionStateInternal?: Ref<HubConnectionState>
}

const createConnection = (baseUrl: string) => {
  const logLevel = process.env.NODE_ENV === 'development' ? LogLevel.Debug : LogLevel.Information

  const getNextRetryDelay = (previousRetryCount: number) => {
    switch (previousRetryCount) {
      case 0:
        return 0

      case 1:
        return 2_000

      case 2:
        return 10_000

      default:
        return 30_000
    }
  }

  return new HubConnectionBuilder()
    .withUrl(baseUrl, {
      // We deliberately don't use useUser here to avoid leaking memory
      // due to the pinia instance belonging to a specific vue app,
      // while the connection instance is accessible globally
      accessTokenFactory: () => {
        let token = ''

        try {
          token = (JSON.parse(localStorage.getItem('ls.authorizationData') ?? '') as AuthInfo).token
        }
        catch {
          // ignore error
        }

        return token
      },
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: ({ previousRetryCount }) => getNextRetryDelay(previousRetryCount),
    })
    .configureLogging(logLevel)
    .build()
}

/**
 * Creates a new SignalR connection or uses one that has already been created.
 * The connection is stored globally to allow sharing it between microfrontends.
 * The connection is monkey patched to make the connection state reactive.
 */
export const useRpcConnection = ({ baseUrl }: {
  baseUrl: string
}): RpcConnection => {
  const connectionRef = useGlobalState<HubConnectionInternal>(
    'rpcConnection',
    () => createConnection(baseUrl),
    true,
  )

  const connection = connectionRef.value

  // Modify the instance to set our reactive state ref on change
  if (!connection.connectionStateInternal) {
    connection.connectionStateInternal = ref(connection.state)
    Object.defineProperty(connection, '_connectionState', {
      get(this: HubConnectionInternal) {
        return this.connectionStateInternal!.value
      },
      set(this: HubConnectionInternal, value) {
        this.connectionStateInternal!.value = value
      },
    })
  }

  const state = computed({
    get: () => connection.connectionStateInternal!.value,
    set: (value) => {
      connection.connectionStateInternal!.value = value
    },
  })

  return { connection, state: readonly(state) }
}
