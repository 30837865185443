import { reactive, toRefs, toValue, watch } from 'vue'

import { RefOrGetter } from '../types'
import { objEntries } from '../utils'

import { useMutableWatchers } from './useMutableWatchers'

/**
 * Create refs that can be synced with other refs later.
 * Used when one composable requires data from another composable that is initialized later.
 *
 * @example enable complex condition in validation schema that relies on the value in the form
 *
 * const { targets: { channelId }, setSources } = useSyncSources<{
 *   channelId: ChannelType,
 * }>({
 *   channelId: ChannelType.None,
 * })
 *
 * // return schema based on channelId
 * const validationSchema = computed(() => channelId.value === ...)
 * const { values } = useBetterForm({ initialValues, validationSchema })
 * setSources({
 *   channelId: () => values.channelId,
 * })
 */
export const useSyncSources = <T extends Record<string, unknown>>(initialValues: T) => {
  const targets = toRefs(reactive({ ...initialValues }))
  const { setWatchers } = useMutableWatchers()

  const setSources = (sources: { [K in keyof T]: RefOrGetter<T[K]> }) => {
    setWatchers(
      objEntries(sources).map(([key, source]) => {
        return watch(() => toValue(source), (value) => {
          targets[key].value = value
        }, { immediate: true, flush: 'sync' })
      }),
    )
  }

  return {
    targets,
    setSources,
  }
}
