import { MaybeRef, Ref, computed, ref, watch } from 'vue'

import { Awaitable } from '../../utils'

import { UseApiRequestMethod } from './types'
import {
  useApiOptions,
} from './internal/useApiOptions'
import { useApiAwaitable, useApiShared } from './internal/useApiShared'
import {
  UseApiOptionsCache,
  UseApiOptionsImmediate,
  UseApiOptionsShared,
  UseApiSharedReturnPublic,
} from './internal/types'
import { omitPrivateKeys } from './internal/utils'

export type UseApiInfiniteOptions<Res, Req, ResArray extends any[]> =
  & Partial<
    & UseApiOptionsImmediate
    & UseApiOptionsShared<Res, Req>
    & UseApiOptionsCache
  >
  & {
    refetch?: MaybeRef<boolean>
    getNextPageParam?: (ctx: NextPageParamContext<Res, Req>) => number | null
    initialPageParam?: number
    getDataArray?: (data: Res) => ResArray
  }

export type NextPageParamContext<Res, Req> = {
  responseData: Res
  requestData: Req
  curPageParam: number
}

export type UseApiInfiniteReturn<Res, Req, ResArray extends any[]> =
  & Omit<UseApiSharedReturnPublic<Res, Req>, 'data'>
  & {
    requestNextPage: (throwOnError?: boolean) => Promise<Res | null>
    data: Ref<ResArray>
    hasNextPage: Readonly<Ref<boolean>>
    fetchingNextPage: Readonly<Ref<boolean>>
  }

/**
 * Wrapper for {@link useApi} for using APIs with infinite scroll.
 *
 * @example
 * const pageSize = 10
 *
 * const { data, loading, fetching, fetchingNextPage, error, requestNextPage, request } = useApiInfinite(
 *   api.getSomethingInfinite,
 *   (pageParam) => [{
 *     page: { number: pageParam } // or page: { skip: pageParam, size: pageSize }
 *   }],
 *   {
 *    // Return what will be passed into the request data getter
 *    getNextPageParam: ({ responseData, curPageParam }) => {
 *       // Return null when no more pages to load
 *       return responseData.total > curPageParam * pageSize ? curPageParam + 1 : null
 *    },
 *
 *    // Initially pageParam will be set to 1, you can override that value
 *    initialPageParam: 1,
 *
 *    // Convenience option to transform the response data into an array that will be merged with previous pages
 *    getDataArray: data => data.items,
 *
 *    // this will automatically reset response data on request data change
 *    refetch: true,
 *   }
 * )
 *
 * // Call on infinite scroll trigger/Load more click
 * requestNextPage()
 *
 * // Call manually to reset response data
 * request()
 */
export const useApiInfinite = <Res, const Req extends any[], ResArray extends any[] = Res[]>(
  requestMethod: UseApiRequestMethod<Res, Req>,
  requestData: (pageParam: number) => NoInfer<Req> | null,
  optionsPartial: UseApiInfiniteOptions<Res, Req, ResArray> = {},
): Awaitable<UseApiInfiniteReturn<Res, Req, ResArray>> => {
  const {
    getNextPageParam = ({ curPageParam }) => curPageParam + 1,
    initialPageParam = 1,
    getDataArray = data => [data] as ResArray,
  } = optionsPartial
  const options = useApiOptions(optionsPartial)

  let nextPageParam: number | null = initialPageParam
  let lastNextPageParam: number | null = null
  const hasNextPage = ref(true)
  const dataArray = ref(
    options.initialData.value
      ? getDataArray(options.initialData.value)
      : [],
  ) as unknown as Ref<ResArray>
  let isInitialData = true
  const requestDataGetter = () => requestData(nextPageParam ?? 0)

  const hookInternal = useApiShared(requestMethod, requestDataGetter, options)

  const clear = () => {
    nextPageParam = initialPageParam
    lastNextPageParam = null
    hasNextPage.value = nextPageParam !== null
    hookInternal.clearData()
    dataArray.value = options.initialData.value
      ? getDataArray(options.initialData.value)
      : [] as unknown as ResArray
    isInitialData = true
  }

  const append = (responseData: Res, requestData: Req) => {
    lastNextPageParam = nextPageParam
    nextPageParam = getNextPageParam({ responseData, requestData, curPageParam: nextPageParam! })
    hasNextPage.value = nextPageParam !== null
    dataArray.value = isInitialData
      ? getDataArray(responseData) as ResArray
      : dataArray.value.concat(getDataArray(responseData)) as ResArray
    isInitialData = false
  }

  hookInternal.onData(append)

  const requestNextPage = async (throwOnError?: boolean): Promise<Res | null> => {
    return hasNextPage.value ? hookInternal.request(throwOnError) : null
  }

  const clearOnNoRequest = () => {
    if (options.clearWhenDisabled.value) {
      clear()
    }
    else {
      hookInternal.lastRequestData.value = null
    }
  }

  const request = async (throwOnError?: boolean): Promise<Res | null> => {
    if (!options.enabled.value || !requestDataGetter()) {
      clearOnNoRequest()
      return null
    }

    clear()

    return requestNextPage(throwOnError)
  }

  const fetchingNextPage = computed(() => !hookInternal.fetching.value && hookInternal.loading.value)

  watch([options.refetch, options.enabled, requestDataGetter], ([refetch, enabled]) => {
    if (!refetch) {
      return
    }

    const curRequestData = lastNextPageParam === null ? null : requestData(lastNextPageParam)
    const isEnabled = enabled && (lastNextPageParam === null || curRequestData)

    if (isEnabled) {
      if (!curRequestData || !options.equalityCheck.value(curRequestData, hookInternal.lastRequestData.value)) {
        clear()
        void requestNextPage()
      }
    }
    else {
      clearOnNoRequest()
    }
  })

  if (options.immediate.value) {
    void request()
  }

  const hook: UseApiInfiniteReturn<Res, Req, ResArray> = omitPrivateKeys({
    ...hookInternal,
    data: dataArray,
    hasNextPage,
    request,
    requestNextPage,
    fetchingNextPage,
    clearData: clear,
  })

  return useApiAwaitable(hook)
}
